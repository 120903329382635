// ==========================================================================
// Sub Banner Card Styles
// ==========================================================================
/* Display Adjustments for Banner */
.banner #subbanner {
    height: @banner-height-subpage;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    .ccms-contentelement {
        margin-bottom: 0 !important;
    }
}

.ccms-banner-overlay {
    .subpages & {display: none;}
}

.ccms-banner-slider {
    .slick-dots {
        .subpages & {display: none !important;}
    }
}

/* Catapult Display Adjustments */
.rspPane {
    .subpages {
        .ccms-banner-slider {height: @banner-height-subpage;}
        .ccms-banner-overlay {display: none !important;}
    }
}

/* Disable Banner Card cataput option controls */
#cardPanel { 
    .rdCommands li:nth-of-type(2), .rdCommands li:nth-of-type(3) {
        &:extend(.ccmsBanner .ccms-banner-slider .ccms-banner-overlay);
    }
}