// ==========================================================================
// Tabbed Content Card Styles
// ==========================================================================
.responsive-tabs__heading{ 
    background-color: #efefef !important;
    font-size: 120%;
    margin-bottom: 10px !important;
    padding: 5px 0 !important;
    text-align: center;
    line-height: normal !important;
    
    @media (max-width: @mobile-breakpoint) {
        border-radius: 0;
        box-shadow: none;
        font-family: @font-primary;
        font-weight: 400;
        font-size: 100% !important;
    }
}
.ccms-tabbedcontent-tab {
    &:extend(.responsive-tabs__heading);
    line-height: 2 !important;
    margin: 0 0.5px !important;
    padding: 0 15px !important;
    font-family: @font-tertiary !important;
    font-size: @font-size !important;
    border-width: 1px 1px 0px !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    letter-spacing: 0.03125rem;
}
    .ccms-tabbedcontent-tab.ccms-tabbedcontent-active, .responsive-tabs__heading--active {
    &:hover, & {
        background: @color-primary !important;
        .text-white  !important;
    } 
}
.responsive-tabs__list__item:hover, .responsive-tabs__heading:hover {
    background: @color-secondary !important;
    .text-white  !important;
}
.responsive-tabs__heading:hover::after {
    border-top: 10px solid #ffffff !important;
}
.responsive-tabs__list {
    border-bottom: 5px solid @color-primary !important;
    margin: 0 !important;
}
.responsive-tabs__panel {
    padding: 20px !important;
}