// ==========================================================================
// Documents List Card Styles
// ==========================================================================

.ccmsDocumentLinkListing {
    font-size: 0.875rem;
    
    &.nobullets { margin-left: 0 !important; }
    
    ul {padding: 0 !important;}
    li {padding: 4px 0 !important;}
    
}



    
