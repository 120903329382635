// ==========================================================================
// Banner Card Styles
// ==========================================================================

/* ============================= Slider ============================= */ 

/*Banner Loading Screen*/
.ccms-banner-slider {
    background: #000000;
    background: url(https://media1.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif) center no-repeat;
    height:40vw;
    .ccms-banner { 
        opacity: 0; 
    }
    
}
/*Banner Loaded*/
.ccms-banner-slider.slick-slider.slick-initialized {
    background: none;
    height: 100%;
    .ccms-banner { 
        opacity: 1;
        position: relative;
        transition: opacity 0.3s ease-in;
    }
}

.ccms-banner-slider,
.slick-list,
.slick-track {height: 100%;}

.slick-slider {
    margin-bottom: 0px;
}

.ccms-contentelement-Banner {
    height: 100%;
}

.ccms-banner {
    padding:0px;
    outline: none; border: none; background-position: center; background-size: cover; background-repeat: no-repeat;
    
    &:after { background-color: transparent; }
}

.banner .slick-slide{height:0px; padding-bottom: 30vw;}

.ccms-banner-overlay {
    position: absolute;
    background: fade(@color-primary, 70%);
    color: contrast(@color-primary, #111, #FFF);
    max-width: 35%;
    left: 56%;
    bottom: 7%;
    padding: 15px;
    text-align: left;
    z-index: 11;
    
    &:before {
        content: " ";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid fade(@color-primary, 70%);
        bottom: 100%;
        left: 0;
        right: 0;
        margin: auto;
    }

    p {
        color: inherit; 
        font-weight: 400; 
        font-size: 1.875rem;
        border-top: none;
        border-bottom: none;
        overflow: initial;
        white-space: normal;
        font-family: @font-primary;
        padding: 0;
        font-style: italic;
    }

    h1 {font-family: @font-secondary; font-size: 2.125rem; font-weight: 100; letter-spacing: 0.03125rem; padding: 1%; white-space: normal; line-height: 1.3;}

    a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited{
        float: right;
        padding: 7px 15px;
        box-shadow: 0px 0px 5px rgba(0,0,0,.25);
        border-radius: 0px;
        background: @color-primary;
        color: contrast(@color-primary, #fff, #111);
/*        color: inherit !important; 
        font-size: 0.75rem !important;

        letter-spacing: 0.03125rem !important;
        border-bottom: none;
        box-shadow: none !important;
        text-transform: uppercase !important;
        box-shadow: none !important;
        background-color: transparent !important;
        display: inline !important;
        padding: 3px !important;*/
        
        strong {font-weight: 500 !important;}

        &:hover {color: #DDD; border-color: #DDD;}
    }
}

.ccms-banner-slider {
    
    .slick-prev, .slick-next { 
        z-index:11;
        width: 35px;
        height: 40px;
        
        
        &:before {
            font-family: @font-icon;
        }
        &:hover,{
            background: rgba(0,0,0,0.8);
        }
    }
    
    .slick-prev {left: 0;
        &:before {content: '\f053';}
    }
    
    .slick-next {right: 0;
         &:before {content: '\f054';}
    }

    .slick-dots {    
        bottom: 0;
        z-index: 11;
        margin: 0 0 5px;

        li button:before {
            content: '\f111';
            font-size: 14px;
            font-family: @font-icon;
            color: #FFF;
            opacity: 0.75;
        }

        li.slick-active button:before {
            color: #FFF;
            opacity: 1;
        }

        li button:hover:before, li button:focus:before {
            color: #FFF;
        } 
    }
}


/* Catapult Display Adjustments */
.rspPane {
    .homepages {
        .ccms-banner-slider {height: @banner-height;}
        .banner {padding-bottom: 0 !important;}
    }
   
    .banner {
        &, .ccmsBanner {height: 100% !important; }
        .ccmsBanner { position:relative !important;
            .ccms-contentelement { margin-bottom: 0;}
        }
        .slick-slider {margin-bottom: 0 !important;}
        .ccms-banner {height: 100% !important;}
        .slick-dots {display: none !important;}
    }
}


/*Preview Pane Adjustments*/

#previewPanel {
    .ccms-banner-slider {
        .slick-dots, .slick-prev, .slick-next { display: none !important;}
    
        .slick-slide {height: 100% !important; padding: 30px !important;}
        
        .ccms-banner-overlay {position: relative !important; bottom: auto !important; right: auto !important; }
    }
}

@media only screen and (max-width: @desktop-breakpoint) {
    .ccms-banner-overlay{
        h1 {font-size: 2.5vw;}
        p {font-size: 2vw;}
    }
}

@media only screen and (max-width: @mobile-breakpoint) {

.banner .slick-slide{height:0px; padding-bottom: 65vw;}

}