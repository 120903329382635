// ==========================================================================
// Helpers / Extensions
// ==========================================================================

// Floats
.left {float: left !important;}
.right {float: right !important;}
.no-float {float: none !important;}

// Display
.block {display: block;}
.inline {display: inline;}
.inline-block {
    display: inline-block;
    *zoom: 1; 
    *display: inline;
}
.none, [hidden] {display: none;}
.vertical-center {line-height: 100px;}

// Position
.absolute-full-bleed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

// Rows
.row-zero {
    padding-top: 0;
    padding-bottom: 0;
}
.row-xs {
    padding-top: @row-xs;
    padding-bottom: @row-xs;
}
.row-s {
    padding-top: @row-s;
    padding-bottom: @row-s;
}
.row-m, .row {
    padding-top: @row-m;
    padding-bottom: @row-m;
}
.row-l {
    padding-top: @row-l;
    padding-bottom: @row-l;
}
.row-xl {
    padding-top: @row-xl;
    padding-bottom: @row-xl;
}
.row-xxl {
    padding-top: @row-xxl;
    padding-bottom: @row-xxl;
}

.row-bottom-zero {
    padding-bottom: 0;
}
.row-bottom-xs {
    padding-bottom: @row-xs;
}
.row-bottom-s {
    padding-bottom: @row-s;
}
.row-bottom-m {
    padding-bottom: @row-m;
}
.row-bottom-l {
    padding-bottom: @row-l;
}
.row-bottom-xl {
    padding-bottom: @row-xl;
}
.row-bottom-xxl {
    padding-bottom: @row-xxl;
}

// Margin Padding Boxes

.padding-2-lr {
	padding-left: 2px!important;
	padding-right: 2px!important;
}	

// Padding
.padded {padding: 15px;}

// Remove Padding
.no-padding {
    padding: 0 !important;
    &-vertical {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    &-horizontal {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media (max-width: @mobile-breakpoint) {
    .mobile-no-gutter {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

// Font Sizes
.font-m {font-size: 100% !important;}
.font-s {font-size: 85% !important;}
.font-xs {font-size: 75% !important;}
.font-l {font-size: 125% !important;}
.font-xl {font-size: 140% !important;}

// Font Weights
.font-ultra-bold {font-weight: 900 !important;}
.font-bold {font-weight: 700 !important;}
.font-normal {font-weight: 500 !important;}
.font-light {font-weight: 300 !important;}
.font-thin {font-weight: 100 !important;}

// Font Styles
.font-normal {font-style: normal;}
.capitalize {text-transform: capitalize;}
.uppercase {text-transform: uppercase;}
.letter-spacing {letter-spacing: 2px;}

// Text Shadow 
.text-shadow {
    #shadow > .text-shadow(1px, 2px, 0, 0.8);
}

// Lists
.list-unstyled {
  list-style: none;
  padding: 0;
}

// Text Position
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-center {text-align: center;}

// Background Cover
.background-cover { 
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-position: center center !important;
}

// Image Spacing
.image-space-right {margin-right: 20px;}
.image-space-left {margin-left: 20px;}

// Colors
.text-white,
.text-white a {color: #fff;}

// Accessibility (offscreen text for screen readers)
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    left: -9999em;
}

// CSS Tables
.css-table {
    display: table !important; 
    width: 100%;
}
.css-table-row {display: table-row !important;}
.css-table-cell {display: table-cell !important;}